@use "../../styles/spacing";

header {
	text-align: center;
	padding-top: 1rem;

	* {
		font-weight: lighter;
	}

	h1 {
		margin: 1rem auto 0.5rem;
		font-size: 4rem;
		text-transform: uppercase;
	}

	h2 {
		margin-block: 0;
		font-size: 2.5rem;
		font-variant: small-caps;
	}
}
@use "../../styles/colors";
@use "../../styles/spacing";

main {
	// display: grid;
	// grid-template-columns: repeat(auto-fit, minmax(min(25ch, 100%), 1fr));
	margin-top: 2rem;
	padding-block: 1rem;
	background-color: colors.$main-background;
	border: 2px solid;
	border-radius: 8px;

	@include colors.accent-color-alpha(border-color, 0.3);

	h2 {
		margin-bottom: 1rem;
		font-size: 2rem;
		text-transform: uppercase;
	}

	h3 {
		font-size: 1.5rem;
	}

	h4 {
		font-size: 1.25rem;
		font-variant: small-caps;
	}

	h5 {
		margin-top: 0.5rem;
		margin-bottom: 0.25rem;
		font-size: 1rem;
	}

	p {
		margin-top: 0;
	}

	.supplemental-list {
		font-size: 0.9rem;
		font-style: italic;
		font-weight: lighter;
	}

	#separator-main {
		width: 2px;
		background-color: colors.$accent-color;
	}
}
@mixin scale-on-hover ($scale: 1.2, $duration: 0.4s, $delay: 0s) {
	transition: transform $duration ease-in-out;

	@media (prefers-reduced-motion) {
		transition: transform calc($duration * 5) ease $delay;
	}

	&:hover {
		transform: scale($scale);
		transition: transform $duration ease-in $delay;

		@media (prefers-reduced-motion) {
			transition: transform calc($duration * 5) ease;
		}
	}
}

@mixin spin($duration: 0.4s, $delay: 0s) {
	$rotation-animation: inline-#{unique-id()};
	$rotation-animation-reduced: inline-#{unique-id()};

	@keyframes #{$rotation-animation} {
		0% {
			transform: rotate(0);
		}

		10% {
			transform: scale(0.8);
		}

		50% {
			transform: rotate(180deg)
		}

		90% {
			transform: scale(1.4);
		}
	}

	@keyframes #{$rotation-animation-reduced} {
		from {
			transform: rotate(0);
		}

		to {
			transform: rotate(1turn);
		}
	}

	animation: $rotation-animation $duration ease-in-out $delay;

	@media (prefers-reduced-motion) {
		animation: $rotation-animation-reduced $duration * 5 1 ease;
	}
}
@use "../../styles/colors";

@mixin summary-spin {
	@media not (prefers-reduced-motion) {
		transition: all 1s cubic-bezier(.68,-0.55,.27,1.55);
	}
}

@mixin summary-spin-back {
	@media not (prefers-reduced-motion) {
		transition: all 0.5s cubic-bezier(.18,.89,.32,1.28);
	}
}

#experience {
	h3, h4 {
		margin-block: 0;
	}

	hgroup p {
		font-style: italic;
		opacity: 0.7;
	}

	.employment-details p {
		line-height: 1.5;
	}

	details {
		.summary-container {
			width: 100%;
			cursor: pointer;
		}

		summary {
			@include summary-spin-back;

			p {
				&::before {
					content: "↓";
					margin-right: 1ch;
				}
				&::after {
					content: "↓";
					margin-left: 1ch;
				}
				&::before, &::after {
					// Needed to transform
					display: inline-block;
					@include summary-spin-back;
				}
			}
		}

		&[open] {
			summary {
				color: colors.$accent-color;
				background-color: colors.$accent-color-text;
				border: 3px solid colors.$accent-color;
				transform: rotate3d(1, 0, 0, 1turn);

				@include summary-spin;

				p {
					&::before {
						transform: rotate(1.5turn);
					}
					&::after {
						transform: rotate(-1.5turn);
					}
					&::before, &::after {
						@include summary-spin;
					}
				}
			}
		}
	}

	summary {
		// Changing this from list-item will remove the ::marker
		display: flex;
		margin-block: 2rem;

		border-radius: 20px;
		background-color: colors.$accent-color;
		color: colors.$accent-color-text;
		text-align: center;

		p {
			margin-top: revert;
			font-size: 1.25rem;
		}
	}

	&:not(.mobile) {
		padding-inline: 3rem;

		.employment-position, .employment-details {
			padding-left: unset;
		}
	}

	&.mobile {
		padding-inline: 2rem;

		h2 {
			text-align: center;
		}

		article {
			h4, h5, hgroup {
				text-align: center;
			}
		}
	}
}
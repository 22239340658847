@use "../../styles/animations";
@use "../../styles/colors";

#info {
	display: flex;
	flex-direction: column;

	h3, h4 {
		margin-block: 0;
	}

	li a {
		// Enables scaling, since anchor elements are normally inline, which transforms do not affect
		display: block;
		@include animations.scale-on-hover($duration: 0.08s, $delay: 0.0s);

		&:hover img {
			@include animations.spin($delay: 0.0s);
		}
	}

	.li-with-icon {
		display: flex;
		align-items: center;
		justify-content: end;
		column-gap: 2ch;

		.contact-icon {
			@include colors.black-to-accent;
		}

		* {
			margin-bottom: 0;
		}

		#logo-linkedin {
			transform: scale(1.1);
		}
	}

	hgroup {
		margin-top: 1rem;

		p {
			margin-bottom: 0;
		}
	}

	.color-accent {
		font-weight: bold;
	}

	.contact-icon {
		height: 3ex;
	}

	.header-extra-space h2 {
		margin-block: 1rem;
	}

	#contact-list {
		margin-block: 0;
		padding: 0;
		list-style: none;
	}

	#tech-expertise-list {
		display: grid;
		grid-template-columns: 1fr 1fr;
		list-style: none;
	}

	&:not(.mobile) {
		margin-inline-start: 2ch;
		padding-inline-end: var(--sections-gap);
		border-right: 1px solid colors.$accent-color;

		* {
			text-align: end;
		}

		p, li {
			line-height: 2;
		}
	}

	&.mobile {
		padding-inline: 1rem;

		* {
			text-align: center;
		}

		li {
			line-height: 2;
		}

		#contact-list {
			.li-with-icon {
				justify-content: center;
			}
		}

		#tech-expertise-list {
			grid-template-columns: repeat(3, 1fr);
			padding-inline: min(5%, 5rem);
			column-gap: 0.5rem;
		}
	}
}
